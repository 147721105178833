import React from 'react'
import PropTypes from 'prop-types'

import ListDetectionWrapperStyled from './styled'

class ListDetectionWrapper extends React.PureComponent {
  getFilterPanel() {
    const menus = this.props.listFilter.map(filterData => {
      let className = 'filter-menu'
      className += filterData.value === this.props.selectedFilter ? ' selected' : ''
      return (
        <div
          id={`${this.props.id}-filter-${filterData.value}`}
          data-test={`${this.props.id}-filter-${filterData.value}`}
          key={filterData.value}
          className={className}
          onClick={() => filterData.onClick(filterData.value)}
        >
          {filterData.text}
        </div>
      )
    })
    return (
      <div id={`${this.props.id}-filter-options`} data-test={`${this.props.id}-filter-options`} className="filter-panel-list-detection-container">
        {menus}
      </div>
    )
  }

  getTitleContainer() {
    let output = null
    let title = null
    let filterPanel = null
    if (this.props.title) {
      title = (
        <div id={`${this.props.id}-header`} data-test={`${this.props.id}-header`} className="title-list-detection">
          {this.props.title}
        </div>
      )
    }
    if (this.props.listFilter.length > 0) {
      filterPanel = this.getFilterPanel()
    }
    if (title || filterPanel) {
      output = (
        <div className="title-list-detection-container">
          {title}
          {filterPanel}
        </div>
      )
    }
    return output
  }

  render() {
    return (
      <ListDetectionWrapperStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        {this.getTitleContainer()}
        <div className="list-detection-container">{this.props.children}</div>
      </ListDetectionWrapperStyled>
    )
  }
}

ListDetectionWrapper.defaultProps = {
  id: 'list-detection-wrapper',
  title: '',
  listFilter: [],
  selectedFilter: '',
}

ListDetectionWrapper.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]).isRequired,
  listFilter: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  selectedFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ListDetectionWrapper
