import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 100%;
  .title-list-detection-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .title-list-detection {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 60px;
      span {
        font-size: 16px;
      }
    }
    .filter-panel-list-detection-container {
      display: flex;
      align-items: center;
      .filter-menu {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        cursor: pointer;
        margin: 0 8px;
        &.selected {
          font-family: 'Prompt-Bold';
          font-weight: bold;
          color: #981214;
        }
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .list-detection-container {
    display: grid;
    grid-template-columns: 356px 356px 356px 356px;
    grid-gap: 16px;
  }
  @media only screen and (max-width: ${device.sizeL}) {
    .list-detection-container {
      grid-template-columns: 251px 251px 251px 251px;
    }
  }
  @media only screen and (max-width: ${device.sizeM}) {
    .title-list-detection-container {
      .filter-panel-list-detection-container {
        .filter-menu {
          font-size: 16px;
        }
      }
    }
    .list-detection-container {
      grid-template-columns: 292px 292px 292px 292px;
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .title-list-detection-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      .title-list-detection {
        font-size: 18px;
        margin: 0px;
      }
      .filter-panel-list-detection-container {
        display: flex;
        align-items: center;
        .filter-menu {
          font-size: 14px;
        }
      }
    }
    .list-detection-container {
      justify-content: center;
      grid-template-columns: repeat(auto-fill, 260px);
    }
  }
`
